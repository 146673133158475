import { SchedulingVariant } from 'screens/ScheduleCall/types'
import { getObject, LocalStorageKeys, setObject } from 'utils/storage'
import { CoachProfile, InsuranceBerryStreetAppointmentType, Slot } from 'types'
import { NOT_ELIGIBLE_PLANS } from './constants'

export interface ReservedAppointment {
  slot: Partial<Slot>
  appointmentType: InsuranceBerryStreetAppointmentType
  pendingAppointmentId?: string | null
  timeZone: string
  coachProfile: CoachProfile
  schedulingVariant: SchedulingVariant
}
interface VideoCallData {
  health_goal?: string
  other_health_goal?: string
  selected_insurance_plan?: string
  ineligible_insurance_plan_selected?: string
  reserved_appointment?: ReservedAppointment | null
  appointment_agenda?: string | null
  slot_reserved_at?: string | null
  heard_about_us?: string
  other_heard_about_us?: string
}

export const getVideoCallData = (): VideoCallData | null =>
  getObject<VideoCallData>(LocalStorageKeys.VideoCallData)

export const setVideoCallData = (videoCallData: Partial<VideoCallData>) => {
  const currentVideoCallData = getVideoCallData()

  setObject(LocalStorageKeys.VideoCallData, { ...currentVideoCallData, ...videoCallData })
}

export const ineligiblePlanSelected = () => {
  const videoCallData = getVideoCallData()

  return (
    videoCallData?.selected_insurance_plan &&
    Object.values(NOT_ELIGIBLE_PLANS).indexOf(videoCallData?.selected_insurance_plan) > -1
  )
}
