import { IConfig } from 'unleash-proxy-client'
import { env } from './env'

const config: IConfig = {
  url: env.REACT_APP_UNLEASH_URL,
  clientKey: env.REACT_APP_UNLEASH_CLIENT_KEY,
  refreshInterval: 15,
  appName: 'web-members',
  environment: env.REACT_APP_ENV,
  disableMetrics: true,
  bootstrap: []
}

export default config
