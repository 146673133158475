import {
  Cloud,
  DotsThree,
  ForkKnife,
  GenderFemale,
  Heartbeat,
  PersonArmsSpread,
  Pulse,
  Syringe,
  Virus,
  Grains,
  HandHeart
} from '@phosphor-icons/react'
import ScaleIcon from 'components/Icons/ScaleIcon'
import { EhrCoachSpecialty } from 'types'

export const OTHER = 'other'
export const NOT_ELIGIBLE_PLANS = {
  'Medicare Advantage': 'medicare_advantage',
  Medicare: 'medicare',
  Other: OTHER
}

export const SELECTED_INSURANCE_PLAN_KEY = 'selected_insurance_plan'
export const INELIGIBLE_INSURANCE_PLAN_SELECTED = 'ineligible_insurance_plan_selected'

export const HEALTH_GOALS = [
  {
    label: 'Weight loss',
    value: 'weight_loss',
    Icon: ScaleIcon
  },
  {
    label: 'Mindful Eating/Intuitive eating',
    value: 'mindful_eating',
    Icon: ForkKnife
  },
  {
    label: 'Gut Health',
    value: 'gut_health',
    Icon: Cloud
  },
  {
    label: 'Insulin Resistance',
    value: 'insulin_resistance',
    Icon: Syringe
  },
  {
    label: 'Athletic Performance',
    value: 'athletic_performance',
    Icon: PersonArmsSpread
  },
  {
    label: 'Women’s Health (Menopause, PCOS, Fertility)',
    value: 'womens_health',
    Icon: GenderFemale
  },
  {
    label: 'Autoimmune disease',
    value: 'autoimmune_disease',
    Icon: Virus
  },
  {
    label: 'Chronic fatigue/Low energy levels',
    value: 'chronic_fatigue',
    Icon: Pulse
  },
  {
    label: 'Heart disease',
    value: 'heart_disease',
    Icon: Heartbeat
  },
  {
    label: 'Intolerances/Food Allergies',
    value: 'food_allergies',
    Icon: Grains
  },
  {
    label: 'Manage Pre Diabetes/Diabetes',
    value: 'manage_pre_diabetes',
    Icon: HandHeart
  },
  { label: 'Other', value: OTHER, Icon: DotsThree }
]

export const coachSpecialtyGoals: Record<EhrCoachSpecialty, string> = {
  [EhrCoachSpecialty.WeightConcerns]: 'weight_loss',
  [EhrCoachSpecialty.AutoimmuneConditions]: 'autoimmune_disease',
  [EhrCoachSpecialty.Bariatric]: '',
  [EhrCoachSpecialty.Cancer]: '',
  [EhrCoachSpecialty.ChronicFatigue]: 'chronic_fatigue',
  [EhrCoachSpecialty.EatingDisorders]: '',
  [EhrCoachSpecialty.Fertility]: 'womens_health',
  [EhrCoachSpecialty.FoodAllergies]: 'food_allergies',
  [EhrCoachSpecialty.GutHealth]: 'gut_health',
  [EhrCoachSpecialty.HealthOptimization]: '',
  [EhrCoachSpecialty.HealthyAging]: '',
  [EhrCoachSpecialty.HeartDisease]: 'heart_disease',
  [EhrCoachSpecialty.MensHealth]: '',
  [EhrCoachSpecialty.MindfulEating]: 'mindful_eating',
  [EhrCoachSpecialty.PrediabetesDiabetes]: 'manage_pre_diabetes' || 'insulin_resistance',
  [EhrCoachSpecialty.PrenatalNutrition]: 'womens_health',
  [EhrCoachSpecialty.SportsPerformance]: 'athletic_performance',
  [EhrCoachSpecialty.ThyroidDisorders]: 'autoimmune_disease',
  [EhrCoachSpecialty.WomensHealth]: 'womens_health'
}

export const HEARD_ABOUT_US = [
  {
    label: 'Facebook',
    value: 'facebook-general'
  },
  {
    label: 'From a friend, family member or coworker',
    value: 'from-friend-family-coworker'
  },
  {
    label: 'From an influencer',
    value: 'from-influencer'
  },
  {
    label: 'Reddit',
    value: 'reddit'
  },
  {
    label: 'Pinterest',
    value: 'pinterest'
  },
  {
    label: 'Youtube',
    value: 'youtube-general'
  },
  {
    label: 'TikTok',
    value: 'tiktok-general'
  },
  {
    label: 'Google or other search engine',
    value: 'google-or-search'
  },
  {
    label: 'Podcast',
    value: 'podcast-general'
  },
  {
    label: 'From a healthcare professional',
    value: 'from-healthcare-pro'
  },
  {
    label: 'Instagram',
    value: 'instagram-general'
  },
  {
    label: 'Press / Magazine',
    value: 'press-magazine'
  },
  { label: 'Other', value: OTHER }
]
