import { Chip, Theme } from '@mui/material'
import { Clock, Lock } from '@phosphor-icons/react'
import { useReservationTimer } from 'screens/VideoCalls/hooks/useReservationTimer'

export const GlobalTimer = () => {
  const { shouldShow, expired, timeLeft } = useReservationTimer()

  if (shouldShow && !expired) {
    return <Chip icon={<Clock size={16} />} label={timeLeft} sx={[styles.chip, styles.timerChip]} />
  }

  if (expired) {
    return (
      <Chip
        icon={<Lock size={16} />}
        label="This time slot may no longer be available"
        sx={[styles.chip, styles.expiredChip]}
      />
    )
  }
  return null
}

const styles = {
  chip: (theme: Theme) => ({
    background: theme.palette.chip.secondary,
    padding: '0px',
    borderRadius: '6px',
    fontSize: '13px',
    position: 'absolute',
    height: '22px',
    right: '24px',
    top: '24px'
  }),
  timerChip: {
    '& .MuiChip-label': {
      width: '44px',
      paddingLeft: '0px',
      marginLeft: '9px',
      paddingRight: '0px'
    }
  },
  expiredChip: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  })
}
