import { lazy, Suspense } from 'react'
import { Loader, PrivateRoute, SnackBar } from 'components'
import { VideoCallFlowStep } from 'components/VideoCallFlowStep'
import { DppPath, GlpPath, LocationPaths, OwnSensorPath } from 'location.types'
import { Navigate, Route, Routes } from 'react-router-dom'
import { VideoCallFlow } from 'screens/VideoCalls/types'
import { NotFound } from './components/NotFound'
import { PageLayout } from './components/PageLayout'

const Auth = lazy(() => import('./screens/Auth').then((module) => ({ default: module.Auth })))

const SignInScreen = lazy(() =>
  import('./screens/SignIn').then((module) => ({ default: module.SignIn }))
)

const ResetPasswordRequestScreen = lazy(() =>
  import('./screens/ResetPasswordRequest').then((module) => ({
    default: module.ResetPasswordRequest
  }))
)

const SurveyScreen = lazy(() =>
  import('./screens/Survey').then((module) => ({
    default: module.SurveyScreen
  }))
)

const SensorSelectionScreen = lazy(() =>
  import('./screens/SensorSelection').then((module) => ({
    default: module.SensorSelection
  }))
)

const FreeVideoCallScreen = lazy(() =>
  import('./screens/FreeVideoCall').then((module) => ({
    default: module.FreeVideoCall
  }))
)

const ScheduleCallScreen = lazy(() =>
  import('./screens/ScheduleCall').then((module) => ({
    default: module.ScheduleCall
  }))
)

const DppChatScreen = lazy(() =>
  import('./screens/Dpp/Chat').then((module) => ({
    default: module.Chat
  }))
)

const DppDashboardScreen = lazy(() =>
  import('./screens/Dpp/Dashboard').then((module) => ({
    default: module.Dashboard
  }))
)

const DppFlowCompletedScreen = lazy(() =>
  import('./screens/Dpp/FlowCompleted').then((module) => ({
    default: module.FlowCompleted
  }))
)

const DppNotEligibleOutcomeScreen = lazy(() =>
  import('./screens/Dpp/NotEligibleOutcome').then((module) => ({
    default: module.NotEligibleOutcome
  }))
)

const DppNotEligibleVideoCallsOutcomeScreen = lazy(() =>
  import('./screens/Dpp/NotEligibleVideoCallsOutcome').then((module) => ({
    default: module.NotEligibleVideoCallsOutcome
  }))
)

const GlpRedirectScreen = lazy(() =>
  import('./screens/Glp/Redirect').then((module) => ({
    default: module.Redirect
  }))
)

const GlpGoalScreen = lazy(() =>
  import('./screens/Glp/Goal').then((module) => ({
    default: module.Goal
  }))
)

const GlpTriedLoseWeightScreen = lazy(() =>
  import('./screens/Glp/TriedLoseWeight').then((module) => ({
    default: module.TriedLoseWeight
  }))
)

const GlpNewApproachScreen = lazy(() =>
  import('./screens/Glp/NewApproach').then((module) => ({
    default: module.NewApproach
  }))
)

const GlpStateScreen = lazy(() =>
  import('./screens/Glp/State').then((module) => ({
    default: module.State
  }))
)

const GlpDateOfBirthScreen = lazy(() =>
  import('./screens/Glp/DateOfBirth').then((module) => ({
    default: module.DateOfBirth
  }))
)

const GlpMedicalHistoryScreen = lazy(() =>
  import('./screens/Glp/MedicalHistory').then((module) => ({
    default: module.MedicalHistory
  }))
)

const GlpBmiScreen = lazy(() =>
  import('./screens/Glp/Bmi').then((module) => ({
    default: module.Bmi
  }))
)

const GlpDescentScreen = lazy(() =>
  import('./screens/Glp/Descent').then((module) => ({
    default: module.Descent
  }))
)

const GlpConditionsScreen = lazy(() =>
  import('./screens/Glp/Conditions').then((module) => ({
    default: module.Conditions
  }))
)

const GlpWeightLossAmountScreen = lazy(() =>
  import('./screens/Glp/WeightLossAmount').then((module) => ({
    default: module.WeightLossAmount
  }))
)

const GlpNotEligibleScreen = lazy(() =>
  import('./screens/Glp/NotEligible').then((module) => ({
    default: module.NotEligible
  }))
)

const GlpNotRightProgramScreen = lazy(() =>
  import('./screens/Glp/NotRightProgram').then((module) => ({
    default: module.NotRightProgram
  }))
)

const GlpBmiTooLowScreen = lazy(() =>
  import('./screens/Glp/BmiTooLow').then((module) => ({
    default: module.BmiTooLow
  }))
)

const GlpSignUpScreen = lazy(() =>
  import('./screens/Glp/SignUp').then((module) => ({
    default: module.SignUp
  }))
)

const GlpMissingInformationScreen = lazy(() =>
  import('./screens/Glp/MissingInformation').then((module) => ({
    default: module.MissingInformation
  }))
)

const GlpEligibilityNextStepsScreen = lazy(() =>
  import('./screens/Glp/EligibilityNextSteps').then((module) => ({
    default: module.EligibilityNextSteps
  }))
)

const GlpShippingScreen = lazy(() =>
  import('./screens/Glp/Shipping').then((module) => ({
    default: module.Shipping
  }))
)

const GlpCheckoutScreen = lazy(() =>
  import('./screens/Glp/Checkout').then((module) => ({
    default: module.Checkout
  }))
)

const GlpSchedulerScreen = lazy(() =>
  import('./screens/Glp/ScheduleCall').then((module) => ({
    default: module.ScheduleCall
  }))
)

const GlpPaymentSuccessfulScreen = lazy(() =>
  import('./screens/Glp/PaymentSuccessful').then((module) => ({
    default: module.PaymentSuccessful
  }))
)

const GlpChatScreen = lazy(() =>
  import('./screens/Glp/Chat').then((module) => ({
    default: module.Chat
  }))
)

const GlpAccountScreen = lazy(() =>
  import('./screens/Glp/Account').then((module) => ({
    default: module.Account
  }))
)

const GlpDashboardScreen = lazy(() =>
  import('./screens/Glp/Dashboard').then((module) => ({
    default: module.Dashboard
  }))
)

const OwnSensorRedirectScreen = lazy(() =>
  import('./screens/OwnSensor/Redirect').then((module) => ({
    default: module.Redirect
  }))
)

const OwnSensorChooseHealthGoalScreen = lazy(() =>
  import('./screens/OwnSensor/ChooseHealthGoal').then((module) => ({
    default: module.ChooseHealthGoal
  }))
)

const OwnSensorWeightLossQuoteScreen = lazy(() =>
  import('./screens/OwnSensor/WeightLossQuote').then((module) => ({
    default: module.WeightLossQuote
  }))
)

const OwnSensorMobileOperatingSystemScreen = lazy(() =>
  import('./screens/OwnSensor/MobileOperatingSystem').then((module) => ({
    default: module.MobileOperatingSystem
  }))
)

const OwnSensorChooseSensorScreen = lazy(() =>
  import('./screens/OwnSensor/ChooseSensor').then((module) => ({
    default: module.ChooseSensor
  }))
)

const OwnSensorEligibilityCheckScreen = lazy(() =>
  import('./screens/OwnSensor/EligibilityCheck').then((module) => ({
    default: module.EligibilityCheck
  }))
)

const CheckoutScreen = lazy(() =>
  import('./screens/Checkout').then((module) => ({
    default: module.Checkout
  }))
)

export const App = () => {
  const surveysPrivate = (
    <PrivateRoute>
      <SurveyScreen />
    </PrivateRoute>
  )

  const surveysPublic = <SurveyScreen />

  const sensorSelectionPrivate = (
    <PrivateRoute>
      <SensorSelectionScreen />
    </PrivateRoute>
  )

  const freeVideoCallPrivate = (
    <PrivateRoute>
      <FreeVideoCallScreen />
    </PrivateRoute>
  )

  const scheduleCallPrivate = (
    <PrivateRoute>
      <ScheduleCallScreen />
    </PrivateRoute>
  )

  const dppChatPrivate = (
    <PrivateRoute>
      <DppChatScreen />
    </PrivateRoute>
  )

  const dppDashboardPrivate = (
    <PrivateRoute>
      <DppDashboardScreen />
    </PrivateRoute>
  )

  const dppFlowCompletedPrivate = (
    <PrivateRoute>
      <DppFlowCompletedScreen />
    </PrivateRoute>
  )

  const glpMissingInformationPrivate = (
    <PrivateRoute>
      <GlpMissingInformationScreen />
    </PrivateRoute>
  )

  const glpEligibilityNextStepsPrivate = (
    <PrivateRoute>
      <GlpEligibilityNextStepsScreen />
    </PrivateRoute>
  )

  const glpSchedulerPrivate = (
    <PrivateRoute>
      <GlpSchedulerScreen />
    </PrivateRoute>
  )

  const glpChatPrivate = (
    <PrivateRoute>
      <GlpChatScreen />
    </PrivateRoute>
  )

  const glpAccountPrivate = (
    <PrivateRoute>
      <GlpAccountScreen />
    </PrivateRoute>
  )

  const glpDashboardPrivate = (
    <PrivateRoute>
      <GlpDashboardScreen />
    </PrivateRoute>
  )

  const checkoutPrivate = (
    <PrivateRoute>
      <CheckoutScreen />
    </PrivateRoute>
  )

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Navigate to={LocationPaths.HealthQuestionnaire} replace />} />
          <Route path={LocationPaths.Auth} element={<Auth />} />
          <Route path={LocationPaths.SignIn} element={<SignInScreen />} />
          <Route
            path={LocationPaths.PasswordResetRequest}
            element={<ResetPasswordRequestScreen />}
          />

          <Route path={LocationPaths.VideoCalls} element={<PageLayout />}>
            {VideoCallFlow.map((step, index) => (
              <Route
                index={index === 0}
                key={index}
                path={step.path}
                element={<VideoCallFlowStep stepIndex={index} />}
              />
            ))}
          </Route>

          <Route path={LocationPaths.Dpp} element={<PageLayout />}>
            <Route path={DppPath.Chat} element={dppChatPrivate} />
            <Route path={DppPath.Dashboard} element={dppDashboardPrivate} />
            <Route path={DppPath.Completed} element={dppFlowCompletedPrivate} />
            <Route path={DppPath.Ineligible} element={<DppNotEligibleOutcomeScreen />} />
            <Route
              path={DppPath.IneligibleVideoCalls}
              element={<DppNotEligibleVideoCallsOutcomeScreen />}
            />
          </Route>

          <Route path={LocationPaths.Glp} element={<PageLayout />}>
            <Route index element={<GlpRedirectScreen />} />
            <Route path={GlpPath.Goal} element={<GlpGoalScreen />} />
            <Route path={GlpPath.TriedLoseWeight} element={<GlpTriedLoseWeightScreen />} />
            <Route path={GlpPath.NewApproach} element={<GlpNewApproachScreen />} />
            <Route path={GlpPath.State} element={<GlpStateScreen />} />
            <Route path={GlpPath.DateOfBirth} element={<GlpDateOfBirthScreen />} />
            <Route path={GlpPath.MedicalHistory} element={<GlpMedicalHistoryScreen />} />
            <Route path={GlpPath.Bmi} element={<GlpBmiScreen />} />
            <Route path={GlpPath.Descent} element={<GlpDescentScreen />} />
            <Route path={GlpPath.Conditions} element={<GlpConditionsScreen />} />
            <Route path={GlpPath.WeightLossAmount} element={<GlpWeightLossAmountScreen />} />
            <Route path={GlpPath.Ineligible} element={<GlpNotEligibleScreen />} />
            <Route path={GlpPath.NotRightProgram} element={<GlpNotRightProgramScreen />} />
            <Route path={GlpPath.BmiTooLow} element={<GlpBmiTooLowScreen />} />
            <Route path={GlpPath.SignUp} element={<GlpSignUpScreen />} />
            <Route path={GlpPath.MissingInformation} element={glpMissingInformationPrivate} />
            <Route path={GlpPath.EligibilityNextSteps} element={glpEligibilityNextStepsPrivate} />
            <Route path={GlpPath.Shipping} element={<GlpShippingScreen />} />
            <Route path={GlpPath.Checkout} element={<GlpCheckoutScreen />} />
            <Route path={GlpPath.PaymentSuccessful} element={<GlpPaymentSuccessfulScreen />} />
            <Route path={GlpPath.ScheduleCall} element={glpSchedulerPrivate} />
            <Route path={GlpPath.Chat} element={glpChatPrivate} />
            <Route path={GlpPath.Account} element={glpAccountPrivate} />
            <Route path={GlpPath.Dashboard} element={glpDashboardPrivate} />
          </Route>

          <Route path={LocationPaths.OwnSensor} element={<PageLayout />}>
            <Route index element={<OwnSensorRedirectScreen />} />
            <Route
              path={OwnSensorPath.ChooseHealthGoal}
              element={<OwnSensorChooseHealthGoalScreen />}
            />
            <Route
              path={OwnSensorPath.WeightLossQuote}
              element={<OwnSensorWeightLossQuoteScreen />}
            />
            <Route
              path={OwnSensorPath.MobileOperatingSystem}
              element={<OwnSensorMobileOperatingSystemScreen />}
            />
            <Route path={OwnSensorPath.ChooseSensor} element={<OwnSensorChooseSensorScreen />} />
            <Route
              path={OwnSensorPath.EligibilityCheck}
              element={<OwnSensorEligibilityCheckScreen />}
            />
          </Route>
          <Route path="/" element={<PageLayout />}>
            <Route path={LocationPaths.HealthQuestionnaire} element={surveysPrivate} />
            <Route path={LocationPaths.Questionnaire} element={surveysPublic} />
            <Route path={LocationPaths.SensorSelection} element={sensorSelectionPrivate} />
            <Route path={LocationPaths.FreeVideoCall} element={freeVideoCallPrivate} />
            <Route path={LocationPaths.ScheduleCall} element={scheduleCallPrivate} />
            <Route path={LocationPaths.Checkout} element={checkoutPrivate} />
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
      <SnackBar />
    </>
  )
}
