import { ApolloLink, NextLink, Operation } from '@apollo/client'

// The backend currently requires HTTP_CLIENT_VERSION to be set with a minimum version. As the client
// version doesn't matter for this project, we are setting it to a high number to ensure it is always valid.
// TODO: Update the backend to ignore this header for the web app; pass web-app specific headers instead
//

const client_version = '99.9.9'
const platform = 'web-members'

class ClientLink extends ApolloLink {
  request(operation: Operation, forward: NextLink) {
    operation.setContext((context: Record<string, any>) => ({
      ...context,
      headers: {
        ...context.headers,
        client_version,
        platform,
        origin_path: window.location.pathname
      }
    }))
    return forward(operation)
  }
}

export const clientLink = new ClientLink()
